<template>
  <div
    class="nav-layout app-background"
    :class="{
      'solid-background': hasSolidBackground
    }"
  >
    <transition
      name="nav"
      appear
    >
      <component
        :is="component"
        v-if="component"
        :logo="logo"
        :shop-name="$shop.name ? $shop.name : ''"
      />
    </transition>
    <div class="app-underlay"></div>
    <div class="app-gradient"></div>
    <slot></slot>
    <powered-by-loop-badge
      v-if="showPoweredByLoop"
      class="nav-layout-badge"
    />
  </div>
</template>

<script>
import HeaderBarOld from '@/components/app/HeaderBar.old';
import PoweredByLoopBadge from '@/components/badges/PoweredByLoopBadge';

export default {
  components: {
    HeaderBarOld,
    PoweredByLoopBadge,
  },
  computed: {
    logo() {
      if (this.$theme) {
        const { logo, navLogo } = this.$theme.branding;
        return navLogo.length ? navLogo : logo;
      }
      return null;
    },
    showPoweredByLoop() {
      return this.$settings.poweredByLoop;
    },
    component() {
      return this.$route.meta.legacyNav ? 'header-bar-old' : null;
    },
    hasSolidBackground() {
      return this.$route.meta.background === 'solid';
    }
  }
};
</script>

<style lang="scss">
.nav-enter-active,
.nav-leave-active {
  transition: opacity 0.12s ease-out;
}

.nav-enter,
.nav-leave-to {
  opacity: 0;
}

.nav-layout.nav-layout {
  overflow: visible;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &.solid-background {
    background-color: white;

    .nav-layout-badge {
      --theme-text-color: white;
      --theme-text-background-color: black;
    }
  }
}

@media screen and (width <= 680px) {
  .nav-layout-badge {
    margin-bottom: var(--spacing-400);
  }
}
</style>
