<template>
  <product-modal
    v-if="product"
    class="product"
    :order="order"
    :candidate="product"
    :status="active"
    :image="image"
    :images="product.images"
    :loading="loading"
    :show-product-description="false"
    @close="closeModal"
    @change-image="changeModalImage"
  >
    <div class="product__sidebar">
      <main
        v-if="product.title && product.variants && !loading"
        class="product__body"
        :class="{
          'no-scroll': !scrollEnabled
        }"
        data-scroll-lock-scrollable
      >
        <div class="product__image">
          <product-images
            class="product__img"
            :selected-image="image"
            :images="product.images"
            control-type="dot"
            @full-screen-pressed="scrollEnabled = false"
            @collapse-pressed="scrollEnabled = true"
          />
        </div>
        <h2 class="product__title">
          {{ product.title }}
        </h2>
        <span
          :class="isDiscounted ? 'original' : ''"
          class="product__price"
        >
          <display-price :amount="price" />
        </span>
        <span
          v-if="isDiscounted"
          :class="isDiscounted ? 'discounted' : ''"
          class="product__price"
        >
          <display-price :amount="product.discounted_price" />
        </span>
        <div
          v-if="product.description && !isProductDescriptionEnabled"
          class="product__description"
          v-html="description"
        ></div>
        <product-options
          v-if="product.options && !isDefault"
          :options="options"
          :variants="product.variants"
          @updated="updateSelected"
        />
        <product-description
          v-if="product.description && isProductDescriptionEnabled"
          :product="product"
        />
        <shop-now-recommendations
          v-if="shouldShowRecommendations"
          :recommendations="product.recommendations"
        />
      </main>
      <main
        v-else
        class="product__loading"
      >
        Loading...
      </main>
      <footer class="product__footer">
        <base-button
          class="product__atc"
          :disabled="!hasVariant || !selectedVariant || cartText !== $content.pageShopNow.optionsSelected"
          :activated="adding"
          @click.prevent="addToCart"
        >
          {{ cartText }}
        </base-button>
      </footer>
    </div>
    <template
      v-if="added"
      #overlay
    >
      <pacer-animation
        :product="selectedVariant"
        @finished="closeModal"
      />
    </template>
  </product-modal>
</template>

<script>
import { each } from 'lodash';
import { BaseButton } from '@loophq/design-system';
import ProductOptions from '@/components/product/ProductOptions';
import ProductDescription from '@/components/product/ProductDescription';
import ProductImages from '@/components/product/ProductImages';
import PacerAnimation from '@/views/Exchange/PacerAnimation';
import ShopNowRecommendations from '@/views/Shop/ShopNowRecommendations';
import { featureFlags } from '@/js/constants/featureFlags';
import { slugs } from '@/js/constants/imageSlugs';
import { formatOptions } from '@/js/helpers/formatProductOptions';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { getFirstCollection, mapActive } from '@/views/Shop/shoputil';
import DisplayPrice from '@/components/globals/DisplayPrice.vue';

export default {
  name: 'ShopNowProduct',
  components: {
    ProductOptions,
    BaseButton,
    ProductDescription,
    ProductImages,
    PacerAnimation,
    ShopNowRecommendations,
    DisplayPrice
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  async beforeRouteUpdate(to, from) {
    disablePageScroll();
    this.loading = true;
    // beforeRouteUpdate handles navigating between products entirely inside modal (e.g. product recommendation).
    // if navigating to a product in different collection, we must load that collection first.
    if (to.params.id !== from.params.id) {
      await this.$store.dispatch('shopnow/getCollection', { collection: { id: to.params.id }, payload: { 'order_id': this.order.id } });
      // ensures active state in the nav reflects collection we just loaded (defaulting to initial collection)
      const initialCollection = getFirstCollection(this.$store.state.tabs);
      const activeCollectionId = to.params.id ? +to.params.id : initialCollection.id;
      this.$store.commit('shopnow/updateNav', mapActive(this.$store.state.shopnow.navigation, activeCollectionId));
    }

    await this.$store.dispatch('products/get', { id: to.params.productId, needsRefreshed: true });
    await this.$store.dispatch('products/getRecommendations', { id: to.params.productId });
    this.loading = false;
  },
  data() {
    return {
      active: true,
      added: false,
      adding: false,
      imageSlugs: slugs,
      loading: false,
      prevRoute: null,
      scrollEnabled: true,
      selected: [],
    };
  },
  computed: {
    store() {
      return this.$store.state.shopnow;
    },
    order() {
      return this.$store.getters.order;
    },
    totals() {
      return this.$store.state.totals.totals;
    },
    productId() {
      return +this.$route.params.productId;
    },
    collectionId() {
      return +this.$route.params.id;
    },
    product() {
      const product = this.store.products.find(item => item.id === this.productId) ?? {};
      const variants = this.$store.state.products.items[this.productId] ?? {};

      return {
        id: this.productId,
        variants: null,
        ...product,
        ...variants,
      };
    },
    options() {
      return formatOptions(this.product.options, this.product.variants ?? []);
    },
    image() {
      if (this.hasVariant && this.selectedVariant.image) {
        return this.selectedVariant.image;
      }

      return this.product.image?.src ? this.product.image.src : this.product.image;
    },
    price() {
      if (this.hasVariant) {
        return this.selectedVariant.price;
      }

      return this.product.price;
    },
    description() {
      if (this.product.description) {
        const limit = 320;
        return this.product.description.length > limit ? `${this.product.description.slice(0, limit)}...` : this.product.description;
      }
      return null;
    },
    hasVariant() {
      if (this.product.options && this.selectedVariants && this.product.variants) {
        return this.selectedVariants.length === 1 || this.product.variants.length === 1;
      }

      return false;
    },
    selectedVariants() {
      if (this.product.variants?.length) {
        return this.product.variants.filter(variant => {
          // Check that every option in the selected array matches the options in
          // this variant
          return this.selected.every(item => variant[item.option] === item.value) || this.product.variants.length === 1;
        });
      }
      return null;
    },
    selectedVariant() {
      if (this.hasVariant) {
        return this.selectedVariants[0];
      }
      return null;
    },
    cartText() {
      if (this.selectedVariant && this.selectedVariant.limit <= (0 + this.inCart)) {
        return this.$content.pageShopNow.optionsSoldOut;
      }

      if (this.selectedVariant && this.selectedVariant.price > this.totals?.available && !this.order.stripe) {
        return this.$content.pageShopNow.optionsNoCredit;
      }

      // Messaging for when we have a valid amount of options, but there's no variant
      if (this.product.variants?.length > 1 && this.hasVariant && !this.selectedVariant) {
        return this.$content.pageShopNow.optionsNotAvailable;
      }

      return this.$content.pageShopNow.optionsSelected;
    },
    inCart() {
      let count = 0;
      if (this.order) {
        each(this.order.line_items, (item) => {
          if (item.new_variant && this.selectedVariant) {
            if (item.new_variant.id === this.selectedVariant.id) {
              count++;
            }
          }
        });
        if (this.order.cart) {
          this.order.cart.forEach((item) => {
            if (this.selectedVariant) {
              if (item.id === this.selectedVariant.id) {
                count++;
              }
            }
          });
        }
      }
      return count;
    },
    isDefault() {
      const count = this.product.options.reduce((accumulator, current) => {
        return accumulator + current.values.length;
      }, 0);

      return count <= 1;
    },
    isProductDescriptionEnabled() {
      return this.$store.getters.settings.productDescription;
    },
    shouldShowRecommendations() {
      return this.$store.getters.hasFeature(featureFlags.PRODUCT_RECOMMENDATIONS) &&
        this.$store.getters.settings.recommendationsEnabled &&
        this.product.recommendations?.length > 0;
    },
    isDiscounted() {
      if (this.product.discounted_price) {
        return this.product.price > this.product.discounted_price;
      }
      return false;
    }
  },
  created() {
    this.$nextTick(async () => {
      const needsRefreshed = this.prevRoute?.path === '/credit' || this.prevRoute?.path === '/';
      await this.$store.dispatch('products/get', { id: this.productId, needsRefreshed });
      await this.$store.dispatch('products/getRecommendations', this.product);
    });
    disablePageScroll();
  },
  methods: {
    closeModal() {
      enablePageScroll();
      this.active = false;

      if (this.collectionId) {
        return this.$router.push({
          name: 'shop',
          params: {
            id: this.collectionId
          }
        });
      }

      return this.$router.go(-1);
    },
    updateSelected(value) {
      this.selected = value;
    },
    async addToCart() {
      this.adding = true;
      const cart = this.order.cart ? [...this.order.cart, this.selectedVariant] : [this.selectedVariant];

      this.$store.commit('updateCart', cart);
      this.$store.commit('return/addNewItem', {
        ...this.selectedVariant,
        variantId: this.selectedVariant.id,
        exchangeType: 'storefront'
      });

      if (this.hasShoppingCart) {
        this.added = true;
        return;
      }

      try {
        const { receipt, errors } = await this.$store.dispatch('totals/get');

        if (errors) {
          throw new Error(errors);
        }

        cart.forEach((item) => {
          if (receipt.newItems[item.id].useStruckPrice) {
            item.price = receipt.newItems[item.id].price;
            item.struckPrice = receipt.newItems[item.id].struckPrice;
            item.shopNowDiscountPercentage = receipt.shopNowDiscountPercentage;
          }
        });

        this.$store.commit('updateOrder', {
          ...this.order,
          cart
        });

        // Open cart
        this.$router.push('/shop/cart');
        this.adding = false;
        this.$trackEvent('add to cart');
      } catch (error) {
        this.$store.commit('launchError', {
          errorType: 'death',
          errorMessage: error[0]
        });

        this.adding = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.original {
  text-decoration: line-through;
}

.discounted {
  padding-left: 0.5rem;
}

.product {
  &__image {
    display: none;
  }

  &__sidebar {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 3.5rem;
      background: linear-gradient(to bottom, white, rgba(white, 0.4) 70%, rgba(white, 0));
    }
  }

  &__body {
    padding-top: 3rem;
    padding-left: 1rem;
    flex-grow: 1;
    overflow: auto;
  }

  &__loading {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    @include responsive-font(1.5rem, 1.25rem);

    font-weight: 500;
    text-align: left;
    flex-shrink: 0;
    margin-bottom: 0.5rem;
  }

  &__price {
    margin: 1rem 0;
    font-size: 0.9375rem;
    flex-shrink: 0;
    color: var(--grey-800);
    opacity: 0.675;
  }

  &__description {
    margin: 1rem 0;
    padding: 0 2rem;
    font-size: 0.9375rem;
    flex-shrink: 0;
    color: $gray-dark;
  }

  &__footer {
    padding: 1rem;
    border-top: 1px solid $gray-light;
  }

  &__atc {
    width: 100%;
  }

  .no-scroll {
    overflow-y: hidden;
  }
}

@media screen and (max-width: $break-medium) {
  .product {
    &__image {
      width: 100%;
      height: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    &__img {
      height: 100%;
      object-fit: cover;
      object-position: center center;
      position: static;
    }
  }
}
</style>
