<template>
  <p class="product-price__container">
    <span
      v-if="showDiscountedPrice"
      class="product-price__discounted-price"
    >
      <display-price :amount="productLineItemFees.creditAmount" />
    </span>
    <span
      v-else-if="product.discount > 0"
      class="product-price__discounted-price"
    >
      <display-price :amount="product.discounted_price" />
    </span>
    <span
      class="product-price__price"
    >
      <display-price :amount="product.price" />
    </span>
  </p>
</template>

<script>
import { featureFlags } from '@/js/constants/featureFlags';
import DisplayPrice from '@/components/globals/DisplayPrice';

export default {
  components: { DisplayPrice },
  props: {
    module: {
      type: String,
      default: null
    },
    product: {
      type: Object,
      required: true
    },
  },
  computed: {
    hasLineItemFeesFlag() {
      return this.$store.getters.hasFeature(featureFlags.PARTIAL_RETURN_CREDIT);
    },
    order() {
      return this.$store.getters.order;
    },
    productLineItemFees() {
      return {
        ...this.order.line_items[this.product.id].lineItemFees,
      };
    },
    showDiscountedPrice() {
      const hasUserInputOutcome = this.product.outcomesSetByWorkflow.find((outcome) => outcome.name === 'UserInput') !== undefined;
      const isUserInputModule = this.module === 'UserInput' || hasUserInputOutcome;

      return this.hasLineItemFeesFlag && isUserInputModule && this.productLineItemFees?.creditAmount;
    },
  }
};
</script>

<style lang="scss">
.product-price {
  &__container {
    span:nth-of-type(2) {
      text-decoration: line-through;
      padding-left: 0.5rem;
    }
  }

  &__price,
  &__discounted-price {
    text-transform: uppercase !important; // For currency display
  }
}
</style>
