<template>
  <main class="app-container tracking-page">
    <div class="tracking-page__preview-banner">
      <alert-banner
        v-if="previewMode"
        class="tracking-page__preview-alert"
        data-testid="tracking_page_preview_alert"
        :heading="previewAlertHeading"
        type="info"
        inline
        :description="previewAlertDescription"
      />
    </div>
    <app-header class="tracking-banner" />
    <base-card
      class="tracking-page__tracking-card tracking-page__delivery-timeline"
      data-testid="tracking_page_delivery_timeline"
    >
      <estimated-delivery-date
        :heading="heading"
        :body="estDeliveryDate"
        :tooltip="estDeliveryDateToolTipContent"
        heading-font-size="display-medium"
        class="tracking-page__delivery-date"
      />
      <event-timeline-steps
        class="tracking-page__event-timeline-steps"
        :order-name="trackingData.orderName"
        :tracker="trackingData.tracker"
        :shipping-status-health="currentShippingStatusHealth"
        :latest-shipping-status="latestShippingStatus"
        :fulfillment-tracking-url="trackingData.fulfillment?.trackingUrl"
      />
    </base-card>

    <base-card
      class="tracking-page__tracking-card tracking-page__tracking-updates"
      data-testid="tracking_page_tracking_updates"
    >
      <recent-updates
        :order-name="trackingData.orderName"
        :tracking-details="orderedTrackingDetails"
        :shipping-status-health="currentShippingStatusHealth"
        :top-level-status-detail="topLevelStatusDetail"
        :tracking-url="trackingUrl"
        :portal-return-url="portalReturnUrl"
      />
    </base-card>
    <div
      v-if="hasShowProductDetailsFlag"
      class="tracking-page__product-information"
      data-test-id="tracking_product_information_section"
    >
      <product-information
        :current-shipping-status="latestShippingStatus.status"
        :portal-return-url="portalReturnUrl"
        :line-items="trackingData.fulfillment?.lineItems"
        :order-name="trackingData.orderName"
      />
    </div>
  </main>
</template>

<script setup>
import EstimatedDeliveryDate from '@/views/ForwardTracking/EstimatedDeliveryDate.vue';
import { AlertBanner } from '@loophq/design-system';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { formatTrackingDate } from '@/js/helpers/formatDate';
import EventTimelineSteps from '@/views/ForwardTracking/EventTimelineSteps.vue';
import RecentUpdates from '@/views/ForwardTracking/RecentUpdates.vue';
import { toSentenceCase } from '@/js/helpers/formatTextCase';
import { useRoute } from 'vue-router';
import { getPortalReturnUrl } from '@/js/helpers/urls';
import { trackEvent } from '@/js/helpers/trackEvent';
import ProductInformation from '@/views/ForwardTracking/ProductInformation.vue';
import { featureFlags } from '@/js/constants/featureFlags';
import { labelStatuses } from '@/js/constants/labels';
import { trackingStatusHealth } from '@/js/constants/tracking';
const store = useStore();
const route = useRoute();

trackEvent('page visits by shop');

const previewMode = computed(() => {
  return route.query?.preview === 'true';
});

const heading = store.getters['content']?.moduleForwardTracking.estimatedDeliveryDateHeading;

const previewAlertHeading = store.getters['content']?.moduleForwardTracking.previewAlertHeading;

const previewAlertDescription = store.getters['content']?.moduleForwardTracking.previewAlertDescription;

const trackingData = computed(() => {
  return store.state.tracking;
});

const hasShowProductDetailsFlag = computed(() => {
  return store.getters.hasFeature(featureFlags.ORDER_TRACKING_PRODUCT_DETAILS);
});

const orderReceivedStatuses = [
  labelStatuses.PRE_TRANSIT,
  labelStatuses.LABEL_CREATED,
];

const shippedStatuses = [
  labelStatuses.IN_TRANSIT,
  labelStatuses.ARRIVED_AT_FACILITY,
  labelStatuses.DEPARTED_FACILITY
];

const outForDeliveryStatuses = [
  labelStatuses.OUT_FOR_DELIVERY
];

const deliveredStatuses = [
  labelStatuses.DELIVERED,
  labelStatuses.ARRIVED_AT_PICKUP_LOCATION
];

const expectedShippingStatuses = [
  ...orderReceivedStatuses,
  ...shippedStatuses,
  ...outForDeliveryStatuses,
  ...deliveredStatuses
];

const shippingWarningStatuses = [
  labelStatuses.AWAITING_INFORMATION,
  labelStatuses.DELAYED,
  labelStatuses.WEATHER_DELAY
];

const currentShippingStatusHealth = computed(() => {
  switch (true) {
    case expectedShippingStatuses.includes(trackingData.value?.tracker?.status):
      return trackingStatusHealth.OK;
    case shippingWarningStatuses.includes(trackingData.value?.tracker?.status):
      return trackingStatusHealth.WARNING;
    default:
      return trackingStatusHealth.ERROR;
  }
});

const topLevelStatusDetail = computed(() => {
  return toSentenceCase(trackingData.value?.tracker?.status_detail);
});

const orderedTrackingDetails = computed(() => {
  const trackingDetailsBase = trackingData.value?.tracker?.tracking_details;
  return trackingDetailsBase?.reverse();
});

const trackingUrl = computed(() => {
  return trackingData.value?.tracker?.public_url ?? '';
});

const trackingStatusHistory = computed(() => {
  return trackingData.value?.tracker?.tracking_details?.map(item => item.status);
});

const latestShippingStatus = computed(() => {
  switch (true) {
    case trackingStatusHistory?.value?.some(status => deliveredStatuses.includes(status)):
      return {
        step: 4,
        status: labelStatuses.DELIVERED
      };
    case trackingStatusHistory?.value?.some(status => outForDeliveryStatuses.includes(status)):
      return {
        step: 3,
        status: labelStatuses.OUT_FOR_DELIVERY
      };
    case trackingStatusHistory?.value?.some(status => shippedStatuses.includes(status)):
      return {
        step: 2,
        status: labelStatuses.IN_TRANSIT
      };
    default:
      return {
        step: 1,
        status: labelStatuses.PRE_TRANSIT
      };
  }
});

const portalReturnUrl = computed(() => {
  const shopDomain = trackingData.value?.trackerHashData?.shopDomain || '';
  const isReturnable = trackingData.value?.fulfillment?.isReturnable === 'true' || trackingData.value?.fulfillment?.isReturnable === true;
  if (latestShippingStatus.value.status === labelStatuses.DELIVERED && trackingData.value?.shippingAddressZip && trackingData.value?.orderName && isReturnable) {
    return getPortalReturnUrl(shopDomain, trackingData.value.orderName, trackingData.value.shippingAddressZip);
  }
  return null;
});

const hasEstDeliveryDate = computed(() => {
  return !!trackingData.value?.tracker?.est_delivery_date;
});

const estDeliveryDate = computed(() => {
  if (hasEstDeliveryDate.value) {
    return formatTrackingDate(trackingData.value?.tracker?.est_delivery_date);
  }

  if (latestShippingStatus?.value?.status === labelStatuses.DELIVERED) {
    return store.getters['content']?.moduleForwardTracking.estimatedDeliveryDateDelivered;
  }

  return store.getters['content']?.moduleForwardTracking.estimatedDeliveryDateComingSoon;
});

// This order was picked so the default message will be “We've received your order and the delivery date will be provided as soon as possible.”
const estDeliveryDateToolTipContent = computed(() => {
  if (latestShippingStatus?.value?.status === labelStatuses.DELIVERED) {
    return store.getters['content']?.moduleForwardTracking.estimatedDeliveryDateTooltipDelivered;
  }

  if (hasEstDeliveryDate.value && currentShippingStatusHealth.value === 'ok') {
    return store.getters['content']?.moduleForwardTracking.estimatedDeliveryDateTooltipOk;
  }

  if (!hasEstDeliveryDate.value && [trackingStatusHealth.WARNING, trackingStatusHealth.ERROR].includes(currentShippingStatusHealth.value)) {
    return store.getters['content']?.moduleForwardTracking.estimatedDeliveryDateTooltipNoDateWarnError;
  }

  return store.getters['content']?.moduleForwardTracking.estimatedDeliveryDateTooltipNoDateOk;
});
</script>

<style lang="scss" scoped>
.app-container {
  margin-top: 0;
  overflow-x: hidden;
}

$block: '.tracking-page';

#{$block} {
  &__tracking-card {
    display: flex;
    justify-content: flex-start;
    background: white;
    flex-wrap: wrap;
    align-items: stretch;
    margin: auto;
    max-width: 980px;
    border-radius: var(--corners);
    border: 1px solid #d8dbdf;
  }

  &__preview-banner {
    display: flex;
    justify-content: center;
    margin-top: var(--spacing-300);
  }

  &__preview-alert {
    max-width: 690px;
  }

  &__delivery-timeline {
    margin-bottom: var(--spacing-400);
  }

  &__tracking-updates {
    padding: 2.5rem;
  }

  .base-text {
    width: 100%;
  }

  &__delivery-date {
    flex-shrink: 0;
    display: flex;
    padding: 2.5rem;
  }

  &__event-timeline-steps {
    flex-grow: 1;
    padding: 2.5rem 0;
  }

  &__shrink-event-timeline {
    max-width: 640px;
  }
}

@media screen and (width <= 1402px) {
  #{$block} {
    justify-content: center;
    text-align: center;

    &__delivery-date {
      width: 100%;
      order: 1;
      padding: var(--spacing-400);
      border-top: 1px solid var(--grey-200);
      border-right: none;
    }
  }
}

@media screen and (width <= 680px) {
  #{$block} {
    &__tracking-card,
    &__product-information {
      margin-left: var(--spacing-300);
      margin-right: var(--spacing-300);
    }
  }
}

:deep(.display-xlarge) {
  font-family: var(--heading-font);
}
</style>
