<template>
  <footer class="ls-cart-footer">
    <span class="ls-cart-footer__total-line">
      <span
        class="ls-cart-footer__total-label"
        :style="{ 'font-family': theme.copyFont }"
      >
        {{ credit.message }}
      </span>
      <span
        class="ls-cart-footer__total-amount"
        :style="{ 'font-family': theme.copyFont }"
      >
        <display-price :amount="credit.amount" />
      </span>
    </span>
    <div class="ls-cart-footer__buttons">
      <base-button
        class="ls-cart-footer__continue"
        :disabled="loading"
        @click.prevent="next"
      >
        {{ $content.pageShopNow.cartButton }}
      </base-button>
    </div>
  </footer>
</template>

<script>
import {
  BaseButton
} from '@loophq/design-system';
import DisplayPrice from '@/components/globals/DisplayPrice.vue';

export default {
  name: 'CartFooter',
  components: {
    BaseButton,
    DisplayPrice
  },
  props: {
    theme: {
      type: Object
    },
    text: {
      type: Object
    },
    cart: {
      type: Array
    },
    total: {
      type: Number
    },
    loading: {
      type: Boolean,
      required: false
    }
  },
  emits: ['next'],
  computed: {
    credit() {
      return {
        message: this.total >= 0 ? this.$content.pageShopNow.creditMessage : this.$content.pageShopNow.owedMessage,
        amount: Math.abs(this.total)
      };
    }
  },
  methods: {
    next() {
      this.$trackEvent('continue to review');
      this.$emit('next');
    }
  }
};
</script>

<style lang="scss" scoped>
.ls-cart-footer {
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid $light-grey;

  &__total-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    color: var(--theme-body-color);
  }

  &__total-label {
    font-family: $font-stack;
    font-size: 0.9375rem;
  }

  &__total-amount {
    font-family: $font-stack;
    font-size: 1.25rem;
    font-weight: 500;
  }

  &__visually-hidden {
    @include visually-hidden;
  }

  &__buttons {
    width: 100%;
    display: flex;
    padding: 0 1rem 1rem;
  }

  &__shop,
  &__continue {
    appearance: none;
    box-sizing: border-box;
    padding: 0.75rem 1.25rem 0.875rem;
    border-radius: $small-radius;
    font-family: $font-stack;
    font-size: 0.9375rem;
    font-weight: 500;
    color: $base-background;
    text-align: center;
    line-height: 1.1;
    background-color: $base-text;
    transition: background-color 0.24s ease, color 0.24s ease;
    flex-grow: 1;
    flex-shrink: 0;
    width: calc(50% - 0.375rem);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
  }

  &__shop {
    background-color: $base-background;
    color: $base-text;
    border: 1px solid $light-grey;
    margin-right: 0.375rem;
  }
}
</style>
