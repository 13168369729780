<template>
  {{ $filters.currency(displayPrice, displayCurrency) }}
</template>

<script>
/* eslint-disable vue/require-prop-types */
import {
  BaseText,
} from '@loophq/design-system';

export default {
  name: 'DisplayPrice',
  components: [
    BaseText
  ],
  props: [
    'amount',
  ],
  computed: {
    exchangeRate() {
      return this.$store.getters['currencies/exchangeRate'];
    },
    displayPrice() {
      return Math.ceil(this.amount * this.exchangeRate);
    },
    displayCurrency() {
      return this.$store.getters['currencies/displayCurrency'];
    }
  }
};
</script>
