<template>
  <div
    v-if="languages.length && languages.length > 1"
    class="language-selector"
  >
    <label
      for="language-selector"
      class="visually-hidden"
    >
      {{ $content.moduleLanguage.selectorLabel }}
    </label>
    <select
      id="language-selector"
      v-model="selected"
      class="language-selector__dropdown"
      :value="value"
    >
      <option
        v-for="language in supportedLanguages"
        :key="language.code"
        :value="language.code"
      >
        {{ language.name }}
      </option>
    </select>
  </div>
</template>

<script>
import ISO6391 from 'iso-639-1';

export default {
  name: 'LanguageSelector',
  props: {
    value: {
      type: String,
      required: false,
      default: () => {
        const localLang = localStorage.getItem('lang');
        return localLang ?? 'en';
      }
    },
    languages: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  emits: [
    'input'
  ],
  data () {
    return {
      selected: this.value
    };
  },
  computed: {
    supportedLanguages() {
      return this.languages.map((code) => {
        return {
          code,
          name: ISO6391.getNativeName(code)
        };
      });
    }
  },
  watch: {
    selected(value) {
      this.$emit('input', value);
    }
  },
};
</script>

<style lang="scss" scoped>
$block: '.language-selector';

#{$block} {
  width: auto;
  margin: 2rem 0;
  display: flex;
  justify-content: center;

  &__dropdown {
    width: auto;
    padding: 0.5rem 1.5rem 0.5rem 1rem;
    height: auto;
    // Need to override some base styles for now
    border-radius: 100px !important;
    border: 1px solid #bdc1c6;
    box-shadow: 0 1px 2px 0 rgba(#3c4043, 0.04);
    line-height: 1.4;
    font-size: 0.875rem;
    // Caret
    background-image:
      linear-gradient(45deg, transparent 50%, currentColor 50%),
      linear-gradient(135deg, currentColor 50%, transparent 50%);
    background-position:
      calc(100% - 18px) calc(50% + 1px),
      calc(100% - 14px) calc(50% + 1px);
    background-size:
      4px 4px,
      4px 4px;
    background-repeat: no-repeat;

    &:focus {
      box-shadow: 0 1px 2px 0 rgba(#3c4043, 0.04), 0 0 0 2px var(--theme-primary-color);
    }
  }
}
</style>
