export const ruleTypes = {
  CONDITION: 'Condition',
  USER_INPUT: 'UserInput',
  RETURN_OUTCOME: 'ReturnOutcome',
  EXCLUDE_OUTCOME: 'ExcludeOutcome',
  KEEP_DONATE: 'KeepDonate',
  REVIEW_REJECT: 'ReviewReject',
  LINE_ITEM_FEES: 'LineItemFees',
  REASON_GROUP_OVERRIDE: 'ReasonGroupOverride',
};

export const outcomes = {
  KEEP_ITEM: 'keep',
  REJECT: 'reject',
  REVIEW: 'review',
  DONATE: 'donate',
};
