export const labelStatuses = {
  AVAILABLE_FOR_PICKUP: 'available_for_pickup',
  CANCELLED: 'cancelled',
  DELIVERED: 'delivered',
  FAILED: 'failed',
  FAILURE: 'failure',
  IN_TRANSIT: 'in_transit',
  NEW: 'new',
  OUT_FOR_DELIVERY: 'out_for_delivery',
  PENDING: 'pending',
  PRE_TRANSIT: 'pre_transit',
  REFUND_REQUESTED: 'refund_requested',
  RETURN_TO_SENDER: 'return_to_sender',
  UNKNOWN: 'unknown',
  QUEUED: 'queued',
  LABEL_CREATED: 'label_created',
  ARRIVED_AT_FACILITY: 'departed_facility',
  DEPARTED_FACILITY: 'departed_facility',
  ARRIVED_AT_PICKUP_LOCATION: 'arrived_at_pickup_location',
  AWAITING_INFORMATION: 'awaiting_information',
  DELAYED: 'delayed',
  WEATHER_DELAY: 'weather_delay'
};
