<template>
  <base-card class="shop-now-preview">
    <base-text
      class="preview-heading"
      type="display-large"
      @click="handleShopClick('shop now preview heading clicked')"
    >
      <render-content :data="headingData">
        {{ headingText }}
      </render-content>
    </base-text>
    <base-text
      v-if="shopNowDiscountPercentage > 0"
      class="preview-discount"
    >
      <span class="preview-discount__highlighted">
        {{ discountPercentageText }}
      </span>
      <render-content>
        {{ discountText }}
      </render-content>
    </base-text>
    <images-preview
      v-if="showImages"
      class="preview-images"
      :style="imagesStyle"
      :products="products"
      :additional-text="additionalProducts"
      :amount="productAmount"
      @click="handleShopClick('shop now preview thumbnails clicked')"
    />
    <div class="preview-row">
      <base-button
        type="secondary"
        :activated="refundActivated"
        :disabled="refundDisabled"
        data-dd-action-name="Refund"
        @click.prevent="triggerRefund"
      >
        <render-content :data="refundData">
          {{ refundText }}
        </render-content>
      </base-button>
      <base-button
        class="preview-primary-action"
        data-dd-action-name="Shop Now"
        @click.prevent="triggerShopNow"
      >
        <render-content :data="shopNowData">
          {{ shopNowText }}
        </render-content>
      </base-button>
    </div>
    <div
      v-if="showHandlingFeeMessage || showShopNowMessage"
      class="preview-row"
    >
      <base-text
        class="preview-note"
        type="body-2"
      >
        <render-content
          v-if="showHandlingFeeMessage"
          class="preview-refund-note"
        >
          &#42;{{ $content.moduleShopPreview.refundNote }}
        </render-content>
      </base-text>
      <base-text
        class="preview-note"
        type="body-2"
      >
        <render-content v-if="showShopNowMessage">
          &#42;{{ $content.moduleShopPreview.shopNowNote }}
        </render-content>
      </base-text>
    </div>
  </base-card>
</template>

<script>
import { BaseCard, BaseButton, BaseText } from '@loophq/design-system';
import ImagesPreview from '@/components/product/ProductImagesPreview';
import { formatCurrency } from '@/js/helpers/formatCurrency';

const formatToCurrency = (val, currency) => {
  return formatCurrency(val, currency).replace('.00', '');
};

export default {
  name: 'ShopNowPreview',
  components: {
    BaseCard,
    BaseButton,
    BaseText,
    ImagesPreview,
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    additionalProducts: {
      type: String,
      required: false,
    },
    creditAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    refundAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    handlingFee: {
      type: Object,
      required: true,
    },
    outcomesEnabled: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    shopNowAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    rules: {
      type: Object,
      required: true,
    },
    onlyExchanges: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  emits: ['shop', 'refund'],
  data() {
    return {
      refundActivated: false,
    };
  },
  computed: {
    displayCurrency() {
      return this.$store.getters['currencies/displayCurrency'];
    },
    exchangeRate() {
      return this.$store.getters['currencies/exchangeRate'];
    },
    headingData() {
      const amount = this.creditAmount * this.exchangeRate;
      return {
        amount: formatToCurrency(amount, this.displayCurrency),
      };
    },
    headingText() {
      if (this.creditAmount > 0) {
        return this.$content.moduleShopPreview.heading;
      }

      return this.$content.moduleShopPreview.headingWithoutCredit;
    },
    shopNowDiscountPercentage() {
      return this.$store.state.totals.receipt.shopNowDiscountPercentage;
    },
    discountPercentageText() {
      return `${this.shopNowDiscountPercentage}%`;
    },
    discountText() {
      return this.$content.moduleShopPreview.shopNowDiscount;
    },
    refundData() {
      const amount = this.refundAmount * this.exchangeRate;
      return {
        amount: formatToCurrency(amount, this.displayCurrency),
      };
    },
    refundText() {
      if (this.refundAmount === 0) {
        // If the return consists of only exchanges, and we don't have any credit, show an exchange message instead
        if (this.onlyExchanges) {
          return this.$content.moduleShopPreview.exchangeButton;
        }

        // If we have a zero value, non-all exchange return, show a "Continue return" message
        return this.$content.moduleShopPreview.zeroRefundButton;
      }

      // We conditionally show a handling fee message and need to show the asterisk here to tie them together
      const addendum = this.showHandlingFeeMessage ? '*' : '';

      // If we only have gift credit enabled, show additional gift credit messaging
      if (this.outcomesEnabled.gift && !this.outcomesEnabled.refund) {
        return `${this.$content.moduleShopPreview.refundButton} ${this.$content.moduleShopPreview.refundStoreCredit}${addendum}`;
      }

      return `${this.$content.moduleShopPreview.refundButton}${addendum}`;
    },
    shopNowData() {
      const amount = this.shopNowAmount * this.exchangeRate;
      return {
        amount: formatToCurrency(amount, this.displayCurrency),
      };
    },
    shopNowText() {
      if (this.creditAmount + this.refundAmount > 0) {
        return this.$content.moduleShopPreview.shopNowButton;
      }

      return this.$content.moduleShopPreview.shopNowButtonWithoutCredit;
    },
    isLargeScreen() {
      return this.$screen.width > 680;
    },
    productAmount() {
      return this.isLargeScreen ? 4 : 6;
    },
    imagesStyle() {
      return {
        '--columns': this.isLargeScreen ? 4 : 3,
      };
    },
    showHandlingFeeMessage() {
      const isExchange = this.refundAmount <= 0;
      let hasHandlingFee = false;
      // Only show the handling fee message if there is a refund note
      if (this.handlingFee) {
        hasHandlingFee = Object.values(this.handlingFee.fees).some(
          (fee) => fee?.is_active && fee?.amount > 0
        );
      }
      const hasRefundNote = this.$content.moduleShopPreview.refundNote !== '';
      return !isExchange && hasHandlingFee && hasRefundNote;
    },
    showShopNowMessage() {
      return Boolean(this.$content.moduleShopPreview.shopNowNote);
    },
    showImages() {
      return this.products?.length || this.loading;
    },
    refundDisabled() {
      return this.rules.banGift && this.rules.banRefund;
    }
  },
  methods: {
    triggerRefund() {
      this.$emit('refund');
      const refundOnly = this.outcomesEnabled.refund && !this.outcomesEnabled.gift;
      const giftOnly = this.outcomesEnabled.gift && !this.outcomesEnabled.refund;
      if (refundOnly || giftOnly) {
        this.refundActivated = true;
      }
    },
    triggerShopNow() {
      this.$emit('shop');
    },
    handleShopClick(event) {
      this.$trackEvent(event);
      this.$emit('shop');
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-now-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preview-heading {
  text-align: center;
  margin-bottom: var(--spacing-500);
  cursor: pointer;
  max-width: 25ch;
}

.preview-discount {
  margin-bottom: var(--spacing-100);

  &__highlighted {
    margin-right: var(--spacing-100);
    font-weight: 700;
    background-color: var(--grey-900);
    padding: var(--spacing-100) calc(var(--spacing-200));
    border-radius: var(--spacing-900);
    color: white;
    text-align: center;
    font-size: 0.75rem;
  }
}

.preview-images {
  margin-bottom: var(--spacing-600);
  cursor: pointer;
  width: 100%;
}

.preview-row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--spacing-300);

  & + & {
    margin-top: var(--spacing-300);
  }
}

.preview-note {
  color: var(--grey-700);
  text-align: center;
}

@media screen and (max-width: $break-small) {
  .shop-now-preview {
    padding: var(--spacing-400);
  }

  .preview-heading {
    text-align: left;
    margin-bottom: var(--spacing-400);
  }

  .preview-images {
    margin-bottom: var(--spacing-500);
  }

  .preview-row {
    grid-template-columns: 1fr;
    grid-gap: var(--spacing-200);
  }

  .preview-primary-action {
    order: -1;
  }
}
</style>
