<template>
  <base-button
    v-bind="$attrs"
    class="progress-button"
    type="secondary"
  >
    <span
      v-if="$slots.default"
      class="progress-main"
    >
      <span
        class="progress-text"
        :class="{ 'center-text': centerText }"
      >
        <render-content :data="customizationData">
          <slot></slot>
        </render-content>
      </span>
      <base-icon
        v-if="!hideArrow"
        class="progress-arrow"
        name="chevron-right"
      />
    </span>
    <template v-if="$slots.content">
      <slot name="content"></slot>
    </template>
  </base-button>
</template>

<script>
import {
  BaseButton,
  BaseIcon,
} from '@loophq/design-system';

export default {
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    hideArrow: {
      type: Boolean,
      default: false,
    },
    centerText: {
      type: Boolean,
      default: false,
    },
    customizationData: {
      type: Object,
      default: () => ({}),
    },
  }
};
</script>

<style lang="scss" scoped>
.progress-button {
  width: 100%;
}

.progress-main {
  width: 100%;
  display: flex;
}

.progress-text {
  @include font-body;

  color: var(--color-slate-800);
  text-align: left;
  flex-grow: 1;

  &.center-text {
    text-align: center;
  }
}

.progress-arrow {
  --icon-color: var(--grey-900);

  margin-right: -0.5rem;
  height: 1.3rem;
  width: 1.3rem;
}
</style>
