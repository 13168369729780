<template>
  <div class="wrapper">
    <div
      ref="captcha"
      class="recaptcha"
    ></div>
    <base-loader class="loader" />
  </div>
</template>

<script>
import { BaseLoader } from '@loophq/design-system';

export default {
  components: {
    BaseLoader,
  },
  emits: [
    'success',
  ],
  mounted() {
    // Recaptcha is added globally via script tag
    grecaptcha.render(this.$refs.captcha, {
      sitekey: this.$settings.captchaKey,
      callback: this.success
    });
  },
  methods: {
    success(token) {
      this.$emit('success', { token });
    },
    reset() {
      grecaptcha.reset();
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  z-index: 0;
}

.recaptcha {
  display: flex;
  justify-content: center;
}

.loader {
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
</style>
