<template>
  <div class="header">
    <div class="back-to-shop-wrapper">
      <back-to-shop class="back-to-shop-small" />
    </div>
    <component
      :is="component"
      v-if="!$theme.branding.logo || logoError"
      class="header-heading"
      :to="{ name: 'lookup' }"
    >
      <base-text
        aria-hidden="true"
        tag="span"
        type="display-xlarge"
      >
        {{ $shop.name }}
      </base-text>
      <visually-hidden v-if="!isLookup">
        <render-content>
          {{ $content.moduleHeader.accessibleLinkText }}
        </render-content>
      </visually-hidden>
    </component>
    <component
      :is="component"
      v-else
      class="header-img"
      :to="{ name: 'lookup' }"
      :class="logoType"
      :style="ifPortrait()"
    >
      <img
        aria-hidden="true"
        :src="$theme.branding.logo"
        :alt="$shop.name"
        @error="handleLogoError"
        @load="checkRatio($event)"
      />
      <visually-hidden v-if="!isLookup">
        <render-content>
          {{ $content.moduleHeader.accessibleLinkText }}
        </render-content>
      </visually-hidden>
    </component>
  </div>
</template>

<script>
import { BaseText, VisuallyHidden } from '@loophq/design-system';
import BackToShop from '@/components/app/BackToShop';

export default {
  components: {
    BaseText,
    VisuallyHidden,
    BackToShop,
  },
  data() {
    return {
      logoType: 'landscape',
      logoWidth: null,
      logoError: false,
    };
  },
  computed: {
    isLookup() {
      return ['lookup', 'gift'].includes(this.$route.name);
    },
    component() {
      return this.isLookup ? 'div' : 'router-link';
    },
  },
  methods: {
    handleLogoError() {
      this.logoError = true;
    },
    checkRatio: function (event) {
      if (event && event.target && event.target.height > event.target.width) {
        this.logoType = 'portrait';
      }
    },
    ifPortrait: function () {
      if (this.logoWidth != null) {
        return { width: this.logoWidth };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-heading {
  text-decoration: none;
  color: var(--theme-text-color);
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 0;
  flex-direction: column;
  margin-bottom: var(--spacing-500);

  &-img {
    display: block;
    margin: auto;
    z-index: 10;
    width: 380px;

    img {
      width: 100%;
    }
  }
}

.back-to-shop-small {
  display: none;
  margin-bottom: var(--spacing-500);
  flex-grow: 4;
}

.back-to-shop-wrapper {
  display: block;
  position: relative;
  width: 100%;
}

@media screen and (max-width: $break-small) {
  .back-to-shop-small {
    display: block;
  }

  .header {
    padding-top: 0.5rem;
    gap: 0.5rem;

    &-img {
      &.landscape {
        display: block !important;
        width: calc(100% - 2rem);
        height: auto !important;

        img {
          width: 100%;
          height: auto !important;
        }
      }
    }
  }
}
</style>
