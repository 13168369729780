<template>
  <section class="support-section">
    <base-text
      v-if="$content.moduleFooter.heading"
      class="support-section__text"
      tag="h2"
    >
      <render-content>
        {{ $content.moduleFooter.heading }}
      </render-content>
    </base-text>
    <base-text class="support-section__text">
      <render-content :data="{ link }">
        {{ $content.moduleFooter.copy }}
      </render-content>
    </base-text>
  </section>
</template>

<script>
import { BaseText } from '@loophq/design-system';

export default {
  name: 'SupportSection',
  components: {
    BaseText
  },
  computed: {
    link() {
      if (!this.$content.moduleFooter.link) {
        const email = this.$shop.support_email;
        return {
          type: 'link',
          attrs: {
            href: `mailto:${email}`
          },
          text: email,
          events: {
            click: () => {
              this.$trackEvent('support contacted');
            }
          }
        };
      }

      return {
        type: 'link',
        attrs: {
          href: this.$content.moduleFooter.link,
          target: '_blank',
          rel: 'noopener'
        },
        text: this.$content.moduleFooter.linkCopy
      };
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.support-section';

#{$block} {
  display: flex;
  flex-direction: column;

  a {
    color: inherit;
  }

  &__text {
    text-align: inherit;
    color: var(--theme-text-color);
  }
}
</style>
