import store from '@/store';
import { featureFlags } from '@/js/constants/featureFlags';

export const generateOnstoreUrl = (page) => {
  const shopNowUtmRedirect = store.getters['content'].global.shopNowUtmRedirect;
  const hasOnstoreProductFeatureFlag = store.getters.hasFeature(featureFlags.ONSTORE_PRODUCT_INFO);
  let productIds = [];
  let productTypes = [];
  if (hasOnstoreProductFeatureFlag) {
    productIds = store.state.return.lineItems
      .map(item => item.productId)
      .filter(n => n);
    productTypes = store.state.return.lineItems
      .map(item => store.state.order.line_items[item.id].product_type)
      .filter(n => n);
  }

  const { loop_subdomain, shop_domain } = store.state.shopContents;
  const { totals, receipt } = store.state.totals;
  let data = {
    loop_return_id: store.state.uuid,
    loop_currency: store.state.currency,
    loop_total: totals.credit.base + totals.credit.bonus.total,
    loop_base: totals.credit.base,
    loop_credit: totals.credit.bonus.total,
    loop_discount_percentage: receipt?.shopNowDiscountPercentage,
    loop_domain: window.location.host,
    loop_subdomain: loop_subdomain,
    loop_redirect_url: page ? `${window.location.host}/#/${page}` : `${window.location.host}/#/credit`,
    utm_redirect: shopNowUtmRedirect,
    loop_product_ids: [...new Set(productIds)].join(','),
    loop_product_types: [...new Set(productTypes)].join(','),
    loop_return_key: store.state.return.key
  };

  const query = Object.entries(data)
    .map(([key, value]) => {
      if (
        ['loop_product_ids', 'loop_product_types'].includes(key) &&
        !hasOnstoreProductFeatureFlag
      ) {
        return;
      }

      if (!value) {
        return;
      }

      return `${key}=${encodeURIComponent(value)}`;
    })
    .filter(n => n)
    .join('&');
  return `https://${shop_domain}?${query}`;
};
