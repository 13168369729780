<template>
  <div class="Slider-wrapper">
    <div class="Slider">
      <!-- Slide top nav, shows title when scrolled -->
      <div
        class="Slider-info__nav__wrapper"
        :class="{ active: showNavTitle }"
      >
        <div class="Slider-info__nav">
          <base-button
            v-if="history.length > 0"
            class="slide-module__back"
            type="icon"
            icon="arrow-left"
            @click="goBack"
          >
            <visually-hidden>
              {{ $content.global.back }}
            </visually-hidden>
          </base-button>
          <transition name="header">
            <div
              v-if="module.heading && showNavTitle"
              class="SM-info__nav__title"
            >
              <base-text
                class="slide-module__fixed-heading"
                aria-hidden="true"
                tag="span"
                type="text-body"
              >
                <render-content :data="module.headingData">
                  {{ module.heading }}
                </render-content>
              </base-text>
            </div>
          </transition>
        </div>
      </div>

      <div class="slide-module__wrap">
        <component
          :is="loadedComponent"
          v-bind="module.props"
          v-if="loadedComponent && !loading"
          :key="module.component"
          class="slide-module__component"
          v-on="events"
        >
          <template #header>
            <div class="slide-module__image">
              <product-images
                v-if="isMobile && module.component === 'SelectVariant'"
                class="slide-module__img"
                :selected-image="image"
                :images="images"
                control-type="dot"
                @fullScreenPressed="fullScreenPressed"
                @collapsePressed="collapsePressed"
              />
              <img
                v-if="image === null && module.component !== 'SelectVariant'"
                class="slide-module__img"
                alt=""
                src="/img/icons/no-image3.svg"
              />
              <img
                v-if="image !== null && module.component !== 'SelectVariant'"
                class="slide-module__img"
                alt=""
                :src="image"
                @error="imageBackup($event, 'no-image3.svg')"
              />
            </div>
            <h2
              v-if="module.heading"
              class="slide-module__heading"
            >
              <render-content :data="module.headingData">
                {{ module.heading }}
              </render-content>
            </h2>
            <base-text
              v-if="module.subheading"
              class="slide-module__subheading"
              theme="subdued"
            >
              {{ module.subheading }}
            </base-text>
            <outcomes-header
              v-else-if="!module.hideSubheading"
              :module-path="modulePath"
              :line-item="hasLineItemFeesFlag ? candidateWithLineItemFees : candidate"
            />
          </template>
        </component>
        <component
          :is="loadingComponent"
          v-else
          :reasons="reasons"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import useUtilMethods from '@/js/mixins/util.js';

const { imageBackup } = useUtilMethods();
</script>

<script>
import {
  BaseText,
  BaseButton,
  VisuallyHidden,
} from '@loophq/design-system';
import { cloneDeep } from 'lodash';
import OutcomesHeader from '@/views/Outcomes/OutcomesHeader';
import ProductController from '@/js/controllers/products';
import ProductImages from '@/components/product/ProductImages';
import { featureFlags } from '@/js/constants/featureFlags';
import { importModule, error } from '@/views/view-utils';
import { outcomes, ruleTypes } from '@/js/constants/workflows';
import SkeletonReason from '@/modules/SkeletonReason';
import SkeletonSlide from '@/modules/SkeletonSlide';

export default {
  components: {
    OutcomesHeader,
    BaseText,
    BaseButton,
    VisuallyHidden,
    ProductImages,
    SkeletonReason,
    SkeletonSlide,
  },
  props: {
    candidate: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    image: {
      type: String,
      required: false
    },
    images: {
      type: Array,
      default: null,
    },
    canExchange: {
      type: Boolean,
      required: false,
      default: true
    },
    canReturn: {
      type: Boolean,
      required: false,
      default: true
    },
    canReplace: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: [
    'confirmReturn',
    'changeImage',
    'changeModule',
    'close',
  ],
  data() {
    return {
      history: [],
      showNavTitle: false,
      module: {
        props: {},
        heading: '',
        headingData: {},
        component: 'ConvertItem'
      },
      selected: {},
      loading: false,
      savedInputData: {},
    };
  },
  computed: {
    loadedComponent() {
      if (this.module.component) {
        return importModule(this.module.component);
      }
      return null;
    },
    loadingComponent() {
      if (!this.history.length || this.module.component === 'SelectReason') {
        return 'SkeletonReason';
      }
      return 'SkeletonSlide';
    },
    events() {
      return {
        scroll: this.scroll,
        change: this.change,
        success: this.success,
        error: this.error,
        heading: this.updateHeading,
        updateCollection: this.updateCollection,
        fullScreenPressed: this.fullScreenPressed,
        collapsePressed: this.collapsePressed,
        saveInputs: this.saveInputs,
      };
    },
    reasons() {
      return this.$store.getters['reasons/getGroup'](this.candidate.reason_group).reasons;
    },
    isMobile() {
      return this.$screen.width <= 680;
    },
    candidateWithLineItemFees() {
      return {
        ...this.candidate,
        lineItemFees: { ...this.order.line_items[this.candidate.id].lineItemFees },
        fallbackLineItemFees: { ...this.order.line_items[this.candidate.id].fallbackLineItemFees },
      };
    },
    modulePath() {
      return [...this.history.map(({ component }) => component), this.module.component];
    },
    hasLineItemFeesFlag() {
      return this.$store.getters.hasFeature(featureFlags.PARTIAL_RETURN_CREDIT);
    },
    hasSmartRecommendations() {
      return this.$settings.smartRecommendationsEnabled && this.$store.getters.hasFeature(featureFlags.SMART_RECOMMENDATIONS);
    },
    isUserInputType() {
      return ['AddComment', 'ImageUpload', 'SelectWarranty', 'YesNoQuestion'].includes(this.module.component);
    },
  },
  async created() {
    // Trigger workflows
    this.loading = true;
    const results = await this.$store.dispatch('workflows/evaluate', {
      lineItem: this.candidate,
      order: this.$store.state.order,
      trigger: 'item-selected'
    });
    this.loading = false;

    this.module.component = 'SelectReason';

    const reasonGroupOverride = results?.find((module) => module.type === ruleTypes.REASON_GROUP_OVERRIDE);
    const showUpsell = this.order.enabled.storefront === 'yes' && !this.candidate?.excluded?.shopNow;
    const cta = {
      heading: this.$content.moduleProductShop.heading,
      button: this.$content.moduleProductShop.submit
    };

    this.module.props = {
      lineItem: this.hasLineItemFeesFlag ? this.candidateWithLineItemFees : this.candidate,
      reasons: reasonGroupOverride?.value
        ? this.$store.getters['reasons/getGroup'](reasonGroupOverride.value.reasonGroupId).reasons
        : this.reasons,
      allowPriceDifference: this.$settings.differentPricedExchangesEnabled,
      allowed: {
        exchange: this.canExchange,
        replace: this.canReplace,
        return: this.canReturn
      },
      excluded: this.candidate.excluded,
      cta: showUpsell ? cta : null,
      scrollEnabled: true,
    };

    const userInputModule = results?.find(result => result.type === 'UserInput');
    if (userInputModule && userInputModule?.value?.type !== 'warranty') {
      this.module.props.question = userInputModule.value.label;
      this.module.props.response = userInputModule.value.response;
      this.module.props.responseId = userInputModule.value.id;
      this.module.props.required = !!userInputModule.value.mandatory ?? true;
      this.module.props.commentLabelText = userInputModule.value.label?.en ?? null;

      this.module.props.nextAction = {
        type: 'loadModule',
        target: 'SelectReason'
      };
      this.module.props.lineItem = {
        ...this.module.props.lineItem,
        photoUploadRequired: this.$store.state.order.line_items[this.candidate.id].photoUploadRequired ?? true,
      };
      this.module.component = this.userInputModuleTarget(userInputModule.value.type);
    }

    const warrantyResult = results?.find(result => result.type === 'Warranty');
    if (userInputModule && userInputModule?.value?.type === 'warranty') {
      // if selected items are for standard returns, do not display the warranty selector
      if (this.$store.state.return.lineItems.length && !this.$store.state.return.workflowGroupId) {
        return;
      }

      this.module.component = 'SelectWarranty';
      this.module.props.responseId = userInputModule.value.id;
      this.module.props.workflowGroupId = warrantyResult?.value.workflowGroupId;
      this.module.props.nextAction = {
        type: 'loadModule',
        target: 'SelectReason'
      };
    }

    this.$emit('changeModule', this.module.component);
  },
  mounted() {
    // preload advanced exchange products for each option
    if (this.candidate.advanced_exchange) {
      this.candidate.advanced_exchange.options
        .forEach((option) => {
          this.$store.dispatch('advancedExchanges/getOption', {
            id: option.id,
            payload: {
              collection_id: option.collection_id,
              tags: this.candidate.tags || []
            }
          });
        });
    }
  },
  methods: {
    confirmReturn() {
      const payload = {
        returnType: this.selected.returnType,
        ...(this.selected.exchangeType ? { exchangeType: this.selected.exchangeType } : {}),
        reason: this.selected.reason,
        comment: this.selected.comment,
        product: this.selected.variant,
        LID: this.candidate.id,
        outcome: this.selected.outcome ?? this.candidate.outcome,
        outcomesSetByWorkflow: this.candidate.outcomesSetByWorkflow,
        storefront: this.selected.storefront
      };

      this.$emit('confirmReturn', cloneDeep(payload));

      this.$emit('close', {
        slideModuleComplete: true
      });
    },
    goBack() {
      // No more slides to go back, just close the modal instead
      if (!this.history.length) {
        this.$emit('close', {
          slideModuleComplete: true
        });
        return;
      }

      // Pull the last item in history
      const lastItem = this.history.slice(-1)[0];
      const { component, props } = cloneDeep(lastItem);

      // remove the user_input from this.selected if we are going back from a user input module
      // this allows the user to revisit the input slide
      // we don't want to do this if the the userinput slide doesn't add a user input responseId
      if (this.isUserInputType && !this.module.props.isReasonComment) {
        const userInputToRemove = this.module.props.responseId?.replace('lineItem.', '');
        if (userInputToRemove) {
          delete this.selected[userInputToRemove];
        }
      }

      // when a reason has been selected, going backwards from the first SelectReason slide
      // and then moving forward again should dislpay the SelectReason options to the user
      if (this.module.component === 'SelectReason' && lastItem.component !== 'SelectReason') {
        this.selected.reason = null;
      }

      if (this.hasLineItemFeesFlag && component === 'YesNoQuestion' && !this.candidateWithLineItemFees.lineItemFees?.creditAmount) {
        this.$store.dispatch('updateLineItem', {
          id: this.candidate.id,
          lineItemFees: this.candidate.lineItemFees ? { ...this.candidate.lineItemFees } : { ...this.candidateWithLineItemFees.fallbackLineItemFees },
        });
      }

      // Swap component, giving time for animations
      // $nextTick is needed in order to ensure that
      // components actually get unloaded and reloaded
      // in the event that we're calling the same component
      if (component) {
        this.module.component = null;
        this.$nextTick(() => {
          setTimeout(() => {
            this.module.component = component;
            this.$emit('changeModule', this.module.component);
            this.module.props = props;
          }, 240);
        });
      }

      // We've fully swapped over to the previous module,
      // so we now remove it from the stack
      this.history.pop();

      // Reset the image if returning to these screens
      if (['ConvertItem', 'SelectProduct', 'SelectAdvancedExchangeProduct'].includes(this.module.component)) {
        this.$emit('changeImage', this.candidate.image, this.candidate.images);
      } else {
        // Otherwise, roll image back to where it was before
        const image = props?.product?.image ?? this.candidate.image;
        const images = props?.product?.images ?? this.candidate.images;
        this.$emit('changeImage', image, images);
      }

      // Reset warranty inputs if returning to SelectWarranty
      if (lastItem.component === 'SelectWarranty') {
        this.removeWarrantyInputs();
      }

      // Reset nav title that shows up on scroll
      this.showNavTitle = false;
    },
    removeWarrantyInputs() {
      const inputKeys = Object.keys(this.$store.state.order.line_items[this.candidate.id]).filter((key) =>
        key.includes('user_input')
      );
      this.savedInputData = {};

      this.$store.commit('return/setWorkflowGroupId', null);

      // Remove image upload
      this.$store.dispatch('removeLineItemProperty', {
        id: this.candidate.id,
        propertyToRemove: 'imageUploads',
      });

      // Remove each user input
      inputKeys.forEach((userInput) => {
        this.$store.dispatch('removeLineItemProperty', {
          id: this.candidate.id,
          propertyToRemove: userInput,
        });
      });
    },
    scroll(event) {
      // TODO: Can we autocalculate this somehow?
      const mobileHeight = 272; // image (240px + 1rem) + heading (1rem)
      const threshold = window.matchMedia('(max-width: 680px)').matches ? mobileHeight : 30;
      this.showNavTitle = event.target.scrollTop > threshold;
    },
    change({ options, product }) {
      if (product && options?.length) {
        const variants = product.variants.filter(variant => {
          return options.every(option => {
            return variant[option.option] === option.value;
          });
        });

        if (variants.length === 1) {
          this.$emit('changeImage', variants[0].image, product.images);
        }
      }
    },

    updateCollection({ products, fullyLoaded }) {
      this.module.props.collection.products = products;
      this.module.props.collection.fullyLoaded = fullyLoaded;
    },

    updateHeading({ heading, subheading, headingData, hideSubheading }) {
      this.module.heading = heading ?? this.module.heading;
      this.module.subheading = subheading ?? null;
      this.module.headingData = headingData ?? {};
      this.module.hideSubheading = hideSubheading ?? false;
    },
    saveInputs(userInput) {
      // remember user input data at the SlideModule level
      // so that we can pass it to the next module
      if (userInput.inputType === 'imageUpload') {
        this.savedInputData.images = userInput;
      }
      if (userInput.inputType === 'addComment') {
        this.savedInputData.comment = userInput;
      }
      this.module.props.savedInputData = this.savedInputData;
    },
    async getRecommendedProductFromReason(reason, lineItem) {
      if (!reason) return;
      if (reason.children && reason.children.length) return;
      if (!this.hasSmartRecommendations) return;
      if (!reason.has_smart_recommendation) return;

      try {
        const { recommended_variant } = await ProductController.getRecommendedProduct({
          variantId: lineItem?.variant_id,
          productId: lineItem?.product_id,
          reasonId: reason.id,
        });
        return recommended_variant;
      } catch (exception) {
        console.error(exception);
        return;
      }
    },
    async success({ action, data }) {
      // Update the object of changes we're tracking
      this.selected = {
        ...this.selected,
        ...data,
      };

      this.loading = true;
      // run the smart recs and workflow evaluation calls simultaneously
      // to improve performance, then we can process them linearly
      const [recommendedVariant, results] = await Promise.all([
        this.getRecommendedProductFromReason(data?.reason, data?.lineItem),
        this.$store.dispatch('workflows/evaluate', {
          lineItem: {
            ...this.selected,
            ...this.candidate,
          },
          order: this.$store.state.order,
          trigger: 'item-selected'
        })
      ]);

      const warrantyResult = results?.find(module => module.type === 'Warranty');
      let warrantyAllowedOutcomes = this.candidate.warrantyAllowedOutcomes ?? {};
      if (warrantyResult && Object.keys(warrantyAllowedOutcomes).length === 0 && this.$store.state.return.workflowGroupId) {
        const response = await this.$store.dispatch('getWarrantyAllowedOutcomes', {
          lineItemId: this.candidate.id,
          workflowGroupId: warrantyResult.value.workflowGroupId,
        });
        warrantyAllowedOutcomes = response.allowed;

        this.candidate.warrantyAllowedOutcomes = warrantyAllowedOutcomes;
        this.candidate.allowed = {
          ...this.candidate.allowed,
          ...warrantyAllowedOutcomes
        };
      } else if (warrantyResult && Object.keys(warrantyAllowedOutcomes).length > 0) {
        // warrantyAllowedOutcomes are already set, indicating we are past the standard return window and within a warranty flow
        this.$store.commit('return/setWorkflowGroupId', warrantyResult.value.workflowGroupId);
      }

      this.loading = false;

      // Smart Recommendations
      if (recommendedVariant) {
        this.module.props.recommendedVariant = recommendedVariant;
        action = {
          type: 'loadModule',
          target: 'SmartRecommendation',
        };
        data = {
          ...data,
          recommendedVariant
        };
      }

      // Evaluate workflows
      const keepDonate = results?.find(module => module.type === ruleTypes.KEEP_DONATE);
      const reviewReject = results?.find(module => module.type === ruleTypes.REVIEW_REJECT);
      if (keepDonate || reviewReject) {
        const outcomes = [keepDonate?.outcome, reviewReject?.outcome].filter(n => n);
        this.selected.outcome = outcomes;
      } else if (this.candidate.outcomesSetByWorkflow?.find(module => module.type === ruleTypes.KEEP_DONATE || module.type === ruleTypes.REVIEW_REJECT)) {
        this.candidate.outcome = null;
        this.selected.outcome = null;
      }

      const excludedOutcomes = results?.find(module => module.type === ruleTypes.EXCLUDE_OUTCOME);
      if (excludedOutcomes) {
        this.module.props.allowed = {
          exchange: this.canExchange,
          replace: this.canReplace,
          return: this.canReturn,
          ...warrantyAllowedOutcomes
        };

        this.module.props.excluded = {
          advancedExchange: excludedOutcomes?.value?.advancedExchange,
          inlineExchange: excludedOutcomes?.value?.inlineExchange,
          storeCredit: excludedOutcomes?.value?.storeCredit,
          refund: excludedOutcomes?.value?.refund,
          shopNow: excludedOutcomes?.value?.shopNow,
        };

        if (excludedOutcomes?.value?.shopNow) {
          this.module.props.cta = null;
        }
      }

      if (!excludedOutcomes) {
        this.module.props.excluded = {
          advancedExchange: false,
          inlineExchange: false,
          storeCredit: false,
          refund: false,
          shopNow: false,
        };

        this.module.props.allowed = {
          exchange: this.canExchange,
          replace: this.canReplace,
          return: this.canReturn
        };
      }

      // If this is a line item rejection, we need to bail out now
      if (reviewReject && reviewReject?.outcome === outcomes.REJECT && !this.order.allowlisted) {
        this.module.component = null;
        this.$emit('close', {
          slideModuleComplete: true
        });
        return false;
      }

      const userInputModule = results?.find(module => module.type === 'UserInput');
      const isWarrantyButSelectedItemsAreStandard = userInputModule?.value?.type === 'warranty' && this.$store.state.return.lineItems.length && !this.$store.state.return.workflowGroupId;
      if (!this.selected.reason?.children?.length && userInputModule && !isWarrantyButSelectedItemsAreStandard) {
        data = {
          ...(data ?? {}),
          nextAction: action,
          question: userInputModule.value.label,
          response: userInputModule.value.response,
          responseId: userInputModule.value.id,
          required: !!userInputModule.value.mandatory ?? data.required,
          workflowGroupId: warrantyResult?.value.workflowGroupId,
          commentLabelText: userInputModule.value.label?.en ?? null,
        };

        action = {
          type: 'loadModule',
          target: this.userInputModuleTarget(userInputModule.value.type),
        };
      }

      // Change the modal image if a product is available
      if (data.product) {
        this.$emit('changeImage', data.product.image, data.product.images);
      }

      if (action?.type === 'loadModule') {
        const reasonGroupOverride = results?.find((module) => module.type === ruleTypes.REASON_GROUP_OVERRIDE);

        this.showNavTitle = false;

        // We specifically need to add in any selected options that we have to our history
        this.history = [
          ...this.history,
          cloneDeep({
            ...this.module,
            props: {
              ...this.module.props,
              ...(data.options ? { selectedOptions: data.options } : {})
            }
          })
        ];

        // Fold new data into our tracked props
        this.module.props = {
          ...this.module.props,
          ...(data ?? {}),
          ...(data.options ? { selectedOptions: data.options } : {}),
          ...(!data.reasons && reasonGroupOverride?.value
            ? { reasons: this.$store.getters['reasons/getGroup'](reasonGroupOverride.value.reasonGroupId).reasons }
            : {}
          ),
          savedInputData: { ...this.savedInputData },
          ...(userInputModule ? {} : { commentLabelText: '' }), // do not carry over user-input labels if userInputModule is not present (falls back to default)
        };

        // Swap component, giving time for animations
        // $nextTick is needed in order to ensure that
        // components actually get unloaded and reloaded
        // in the event that we're calling the same component
        this.module.component = null;
        this.$nextTick(async () => {
          this.module.component = action.target;
          this.$emit('changeModule', this.module.component);

          // Load the full collection, we are just passing a dummy collection out always
          if (data.collection?.loading) {
            const collection = await this.$store.dispatch('collections/get', { id: data.collection.id });
            this.module.props = {
              ...this.module.props,
              collection
            };
          }
        });
      } else {
        this.module.component = null;
        this.confirmReturn();
      }
    },
    fullScreenPressed() {
      this.module.props.scrollEnabled = false;
    },
    collapsePressed() {
      this.module.props.scrollEnabled = true;
    },
    userInputModuleTarget(type) {
      const options = {
        'comment': 'AddComment',
        'upload': 'ImageUpload',
        'warranty': 'SelectWarranty',
      };
      return options[type] ?? 'YesNoQuestion';
    },
    error
  },
};
</script>

<style lang="scss" scoped>
$block: '.slide-module';

#{$block} {
  &__fixed-heading {
    font-weight: 700;
  }

  &__heading {
    margin-bottom: 0.5rem;
    text-align: left;
    font-size: 1.5rem;
  }

  &__subheading {
    @include font-body;
  }

  &__wrap {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    // 100% - header height
    height: calc(100% - 3.5rem);
  }

  &__back {
    position: absolute;
    z-index: 1000;
    left: var(--spacing-300);
    top: var(--spacing-300);
    transition-property: color var(--transition-300), background-color var(--transition-300), transform var(--transition-300);
    color: var(--grey-900);

    &:hover {
      transform: translate3d(-2px, 0, 0);
    }
  }

  &__component {
    flex-grow: 1;
  }

  &__image {
    display: none;
  }
}

@media screen and (width <= 680px) {
  #{$block} {
    &__image {
      height: 160px;
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
      flex-shrink: 0;
    }

    &__img {
      height: 100%;
      object-fit: contain;
      width: auto;
    }
  }
}
</style>
