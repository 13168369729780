<template>
  <div class="app-footer">
    <language-selector
      v-model="language"
      :languages="supportedLanguages"
      @input="handleLanguageChange"
    />
    <blurred-background
      v-if="$content.moduleFooter.heading || $content.moduleFooter.copy"
    >
      <support-section class="support-section" />
    </blurred-background>
  </div>
</template>

<script>
import LanguageSelector from '@/components/app/LanguageSelector';
import SupportSection from '@/components/app/SupportSection';
import BlurredBackground from '@/components/layout/BlurredBackground';
import { mapState } from 'vuex';

export default {
  components: {
    LanguageSelector,
    SupportSection,
    BlurredBackground,
  },
  computed: {
    language: {
      get() {
        return this.$store.state.language;
      },
      set(value) {
        this.$store.dispatch('setLanguage', value);
      }
    },
    ...mapState({
      supportedLanguages: state => state.supportedLanguages,
    }),
  },
  methods: {
    handleLanguageChange(value) {
      this.$store.dispatch('setLanguage', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-600) 0 var(--spacing-900) 0;
  margin: auto;
  max-width: 26rem;
}

.support-section {
  text-align: center;
}

@media screen and (max-width: $break-small) {
  .app-footer {
    padding: var(--spacing-500) 0 var(--spacing-600) 0;
  }
}
</style>
