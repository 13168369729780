<template>
  <button
    :id="id"
    class="credit-option card"
    :class="[
      { activated: option === loadingStatus },
      { invalid: validation },
      { outline: data.active },
    ]"
    @click="attemptAction"
  >
    <div class="credit-option-info">
      <div class="credit-option-total-header">
        <div
          v-if="data.id != giftCardWithShopCash"
          class="credit-option-info__header"
        >
          <base-text
            class="credit-option-info__header"
            tag="span"
            type="display-small"
          >
            <render-content>
              {{ data.title }}
            </render-content>
          </base-text>
        </div>
        <div
          v-else
          class="credit-option-info__header"
        >
          <base-text
            class="credit-option-info__header"
            tag="span"
            type="display-small"
          >
            <render-content>
              {{ data.title }}
            </render-content>
          </base-text>
          <img
            alt="Shopify Shop logo"
            src="../../assets/ShopCash/shopLogoBoxed.svg"
            class="shop-logo"
          />
        </div>
        <div class="credit-option-value">
          <base-text
            v-if="!isHandlingFeeLoading"
            class="price"
            tag="span"
            type="display-small"
          >
            <display-price :amount="amount" />
          </base-text>
          <div
            v-if="isHandlingFeeLoading"
            class="credit-option-fee-loader"
          >
            <base-loader />
          </div>
        </div>
      </div>
      <p class="credit-option-copy">
        <span v-if="data.id === giftCardWithShopCash">
          Receive {{ $filters.currency(totals.credit.base, displayCurrency ) }} in store credit to
          {{ displayShopName }}, and an extra
          {{ $filters.currency(totals.credit.bonus.platformCredit.addOnBonusAmount,
                               displayCurrency) }}
          Shop Cash instantly.
          <base-button
            v-if="data.id === giftCardWithShopCash"
            class="credit-option-learn-more"
            type="link"
            size="small"
            :activated="false"
            @click="$emit('toggle-more-information')"
          >
            {{ $content.pageCredit.whatsThis }}
          </base-button>
        </span>

        <render-content
          v-else
          :data="{ shopName: $store.getters.shop.name }"
        >
          {{ data.copy }}
        </render-content>
      </p>
    </div>
  </button>
</template>

<script>
/* eslint-disable vue/require-prop-types */
import { BaseLoader, BaseButton, BaseText } from '@loophq/design-system';
import MarkupRenderer from '@/components/renderers/MarkupRenderer.vue';
import DisplayPrice from './DisplayPrice.vue';
import { storeCreditTypes } from '@/js/constants/returns';

export default {
  components: {
    BaseLoader,
    BaseButton,
    MarkupRenderer,
    DisplayPrice,
    BaseText,
  },
  props: [
    'data',
    'totals',
    'loadingStatus',
    'option',
    'fee',
    'validation',
    'flowCheck',
    'rules',
    'amount',
    'id',
  ],
  emits: ['toggle-more-information'],
  data() {
    return {
      toggleExplanation: false,
    };
  },
  computed: {
    displayCurrency() {
      return this.$store.getters['currencies/displayCurrency'];
    },
    displayShopName() {
      return this.$store.getters.shop.name;
    },
    giftCardWithShopCash() {
      return storeCreditTypes.GIFT_CARD_WITH_SHOP_CASH;
    },
    showBonus: function () {
      if (this.data.showBonus && this.data.bonusAmount > 0) {
        return true;
      }
      return false;
    },
    showFee: function () {
      if (this.data.handlingFee?.setByWorkflows) {
        return true;
      }
      if (this.flowCheck) {
        const isFeeActive = this.data.handlingFee?.is_active;
        if (this.data.showFee && isFeeActive && !this.flowCheck.noFee) {
          return true;
        }
      }
      return false;
    },
    handlingFee: function () {
      return this.data.handlingFee?.amount;
    },
    isHandlingFeeLoading: function () {
      return this.$store.getters['fees/loading'];
    },
    refund() {
      if (this.showFee) {
        var refund = this.totals.credit.preCalculated.handlingFee;
        if (refund < 0) {
          return 0;
        } else {
          return refund;
        }
      }
      return this.totals.credit.base;
    },
  },
  methods: {
    attemptAction: function () {
      let shouldTakeAction = true;
      switch (this.option) {
        case 'gift':
          shouldTakeAction = !this.rules.banGiftCard;
          break;
        case 'refund':
          shouldTakeAction = !this.rules.banRefund;
          break;
        case 'SWC':
          shouldTakeAction = !this.rules.banExchange;
          break;
      }

      if (shouldTakeAction) {
        this.data.action(this.option);
      } else {
        return false;
      }
    },
    close() {
      this.toggleExplanation = false;
    },
  },
  render: (h) => h(this),
};
</script>

<style lang="scss" scoped>
@media screen and (width <= 900px) {
  .credit-option-learn-more {
    font-size: 0.75rem !important;
  }
}

.card {
  border-width: 2px;
  width: 354px;
}

.credit-option {
  padding: 20px;
  display: inline;
  border-radius: 16px;

  &:hover {
    border-width: 2px !important;
  }
}

.credit-option.activated {
  border-width: 2px !important;
}

.credit-option-copy {
  max-width: 80%;
}

.credit-option-value {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 0.75rem;
  margin-right: 0;
  width: auto;
  font-weight: 600;
}

.credit-option-total-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.credit-option-learn-more {
  font-size: 0.9rem;
}

.credit-option-info {
  margin-right: 0;
}

.outline {
  border: 2px solid var(--primary-color) !important;
}

.credit-option-fee-loader {
  .credit-option-fee {
    margin-top: var(--spacing-200);
    text-align: center;
  }
}

.shop-logo {
  width: 2.5rem;
  height: auto;
  margin-left: var(--spacing-100);
}
</style>
