import axios from 'axios';
import store from '@/store';

export default {
  getRecommendedProduct({ reasonId, variantId, productId }) {
    const shopId = store.getters.shop.id;
    return axios.get(`api/v1/${shopId}/product/${productId}/variants/${variantId}/smart-recommendation/${reasonId}`)
      .then((res) => {
        return res.data;
      });
  }
};
