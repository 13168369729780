export const featureFlags = {
  ENABLE_CUSTOMER_PORTAL_INFORMATION_BANNER: 'enable-customer-portal-information-banner',
  EXCHANGE_ORDER_CHECKOUT: 'exchange-order-checkout',
  INSTANT_REFUNDS: 'instant-refunds',
  MODIFY_IX_CHARGE: 'modify-ix-charge',
  OLIVE: 'olive',
  ONSTORE_PRODUCT_INFO: 'onstore-product-info',
  PRODUCT_RECOMMENDATIONS: 'product-recommendations',
  RETURN_SUMMARY_NEGATIVE_POSITIVE_IDENTIFIERS: 'return-summary-negative-positive-identifiers',
  SIGNIFYD: 'signifyd',
  GORGIAS: 'gorgias',
  PARTIAL_RETURN_CREDIT: 'partial-return-credit',
  GENERATE_QR_CODES_FOR_POS: 'generate-qr-codes-for-pos',
  SMART_RECOMMENDATIONS: 'smart-recommendations',
  EXCHANGE_TRACKING: 'exchange-tracking',
  SHOW_THREE_HAPPY_RETURNS_LOCATIONS: 'show-three-happy-returns-locations',
  HAPPY_RETURNS_REMOVED_LOCATIONS_ALERT_BANNER: 'happy-returns-removed-locations-alert-banner',
  ASYNC_RETURNS_PROCESSING: 'async-returns-processing',
  FORWARD_TRACKING_ENABLED: 'forward-tracking-enabled',
  SHOP_CASH: 'shop-cash',
  FRAUD_TOOLS: 'fraud-tools',
  COMBINE_LABELS_WITH_PACKING_SLIPS: 'combine-labels-with-packing-slips',
  REBUY_PRODUCT_RECOMMENDATIONS: 'rebuy-product-recommendations',
  TAX_INCLUSIVE_PACKING_SLIP_PRICES: 'tax-inclusive-packing-slip-prices',
  RETURN_COVERAGE: 'return-protection',
  PAYMENTS_STRIPE_3DS2: 'payments-stripe-3ds2',
  ORDER_TRACKING_PRODUCT_DETAILS: 'order-tracking-product-details'
};
