import { currencyDecimalPlaces } from '@/js/constants/currency';

/**
 * Returns a number that can be used to divide an amount in cents to get the equivalent dollar amount
 * @param {number} decimals
 * @returns {number}
 */
export const getCurrencyConstant = (decimals = 0) => {
  return +'1'.padEnd(decimals + 1, 0);
};

export const getCurrencyAmount = (amount, currency) => {
  if (amount === undefined) {
    return amount;
  }

  const decimals = currencyDecimalPlaces.get(currency);
  const constant = getCurrencyConstant(decimals);
  if (constant) {
    return amount / constant;
  }
  return amount;
};

/**
 * Formats a currency amount into a human-readable string
 * @param {number} amount - The currency amount to format
 * @param {string} currency - Three letter currency code
 * @returns {string}
 */
export const formatCurrency = (amount, currency = 'USD', locale = navigator.language) => {
  const decimals = currencyDecimalPlaces.get(currency);
  const constant = getCurrencyConstant(decimals);

  if (amount !== undefined && constant) {
    return new Intl
      .NumberFormat(
        [locale, 'en-US'],
        { style: 'currency', currency }
      )
      .format(amount / constant);
  }

  return amount;
};

export { currencyDecimalPlaces } from '@/js/constants/currency';
