import Return from '@/js/controllers/return';
import useStorageMethods from '@/js/mixins/store';
const {
  getStore,
  setStore,
} = useStorageMethods();
import { logError } from '@/js/helpers/errors';


const mutationsToTriggerUpdates = [
  'return/addLineItem',
  'return/removeLineItem',
  'return/addNewItem',
  'return/updateNewItems',
  'return/removeNewItem',
  'return/setCreditType',
  'return/setExchangeType',
  'return/setCartId',
];

export const updateTotals = (mutation, Store) => {
  if (mutationsToTriggerUpdates.includes(mutation.type)) {
    Store.dispatch('totals/update');
  }

  if (mutation.type === 'return/clear') {
    Store.commit('totals/setTotals', { totals: null, receipt: null, shippable: null });
  }
};

export default {
  namespaced: true,
  state: {
    /** @type {object} */
    totals: null,
    /** @type {object} */
    receipt: null,
    /** @type {object} */
    shippable: null,
    /** @type {Promise} */
    pending: null,
    /** @type {string|array} */
    errors: null,
    /** @type {boolean} */
    waiveFees: false,
  },
  mutations: {
    /**
     * @param {object} state
     * @param {Promise|null} pending
     */
    setPending(state, pending) {
      state.pending = pending;
    },
    /**
     * @param {object} state
     * @param {object} data
     * @param {object} data.totals
     * @param {object} data.receipt
     * @param {object} shippable
     */
    setTotals(state, { totals, receipt, shippable }) {
      state.totals = totals || null;
      state.receipt = receipt || null;
      state.shippable = shippable || { outcome: 'default' };
      setStore('totals', state);
    },
    /**
     * @param {object} state
     * @param {string|array} errors
     * */
    setErrors(state, errors) {
      state.errors = errors || null;
    },
    setState(state, data) {
      Object.entries(data).forEach(([key, value]) => {
        state[key] = value;
      });
    },
    setWaiveFees(state, waiveFees) {
      state.waiveFees = waiveFees;
      setStore('totals', state);
    }
  },
  actions: {
    /**
     * Get the current totals data
     * @param {object} state
     * @returns {Promise}
     */
    get({ state }) {
      // We're automatically requesting this as state data changes
      // so if a request is pending we want to just return that promise
      if (state.pending) {
        return state.pending;
      }

      // The data is up to date so immediately resolve the promise with the current data
      const { totals, receipt, shippable, errors } = state;
      return Promise.resolve({
        totals,
        receipt,
        shippable,
        errors,
      });
    },
    update({ rootState, commit, rootGetters, state }) {
      // Clear any old errors
      commit('setErrors', null);

      // eslint-disable-next-line no-async-promise-executor
      const request = new Promise(async (resolve, reject) => {
        try {
          const payload = {
            ...rootState.return,
            orderId: rootState.order.id,
            returnPolicy: rootState.order.return_policy,
            returnMethod: rootState.order.returnMethod,
            carrierChoice: rootState.order.carrierChoice,
            fees: !state.waiveFees ? rootGetters['fees/handlingFeeAmounts'] : null
          };
          const { errors, totals, receipt, shippable, address, customer } = await Return.getTotals(payload);

          if (errors) {
            console.warn('Totals request returned errors: ', errors);
            commit('setErrors', errors);
          }

          // Revert exchange type when we know we have just a refund
          const hasExchanges = rootState.return?.lineItems?.some(lineItem => {
            return lineItem?.returnType === 'exchange';
          });

          if (!rootState.return?.newItems?.length && !hasExchanges) {
            rootState.return.exchangeType = 'regular';
          }

          commit('setTotals', {
            totals,
            receipt,
            shippable,
          });

          if (!rootState.return.address) {
            commit('return/setAddress', address, { root: true });
          }

          if (!rootState.return.customer) {
            commit('return/setCustomer', customer, { root: true });
          }

          commit('setPending', null);
          resolve({
            totals,
            receipt,
            shippable,
            errors: errors || null
          });
        } catch (error) {
          logError(error);
          const errors = error.response?.data?.errors;
          if (errors) {
            commit('setErrors', errors.join(' - '));
          }
          commit('setPending', null);
          reject(error);
        }
      });

      commit('setPending', request);
      return request;
    },
    hydrate({ commit }) {
      const saved = getStore('totals');

      if (saved) {
        // Always set pending to null since a pending promise will get saved
        // in localstorage as an empty object
        commit('setState', {
          ...saved,
          pending: null
        });
      }
    }
  },
  getters: {
    isLoading(state) {
      return !!state.pending;
    }
  }
};
