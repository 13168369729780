<template>
  <header
    class="ls-cart-header"
    :style="{ 'font-family': theme.copyFont }"
  >
    <button
      class="ls-cart-header__close legacy-button"
      @click.prevent="toggleCart"
    >
      <span class="ls-cart-header__visually-hidden">Close exchange cart</span>
      <svg
        class="ls-cart-header__icon"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      ><path
        d="M7.621 4.286c.284-.35.846-.382 1.176-.077.33.306.363.888.057 1.22l-3.19 3.714h10.508c.457 0 .828.384.828.857s-.37.857-.828.857H5.664l3.19 3.714c.305.332.264.913-.066 1.219-.33.306-.884.275-1.167-.076l-4.414-5.143c-.287-.416-.265-.789 0-1.143l4.414-5.142z"
        fill="currentColor"
        fill-rule="evenodd"
      /></svg>
    </button>
    {{ $content.pageShopNow.cartHeading }}
  </header>
</template>

<script>
export default {
  name: 'CartHeader',
  props: {
    theme: {
      type: Object
    },
    text: {
      type: Object
    },
    cart: {
      type: Array
    }
  },
  emits: ['toggle'],
  methods: {
    toggleCart() {
      this.$trackEvent('cart closed');
      this.$emit('toggle');
    }
  }
};
</script>

<style lang="scss" scoped>
.ls-cart-header {
  $parent: &;

  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  padding: 1rem;
  font-family: $font-stack;
  font-size: 1rem;
  font-weight: 600;
  color: $base-text;
  text-align: center;
  border-bottom: 1px solid $light-grey;
  height: 4.5rem;

  &__close {
    position: absolute;
    left: 0.5rem;
    top: calc(50% - 22px);
    appearance: none;
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $base-text;
    cursor: pointer;
    transition: color $easing-regular;

    &:hover,
    &:focus {
      box-shadow: none;
      outline: none;
      color: black;

      #{$parent}__icon {
        transform: translate3d(-3px, 0, 0);
      }
    }
  }

  &__icon {
    width: 22px;
    height: 22px;
    transition: transform $easing-regular;
  }

  &__visually-hidden {
    @include visually-hidden;
  }
}
</style>
