<template>
  <div class="ls-sidecart-product">
    <span
      class="ls-sidecart-product__image"
    >
      <img
        v-if="image"
        :src="image"
        :alt="product.title"
      >
      <span
        v-if="product.shopNowDiscountPercentage"
        class="ls-sidecart-product__image-badge"
      >
        {{ product.shopNowDiscountPercentage }}
      </span>
    </span>
    <span class="ls-sidecart-product__info">
      <span
        v-if="product.title"
        class="ls-sidecart-product__title"
      >
        {{ product.title }}
      </span>
      <span
        v-if="variants"
        class="ls-sidecart-product__variants"
      >
        {{ variants }}
      </span>
      <span class="ls-sidecart-product__footer">
        <base-button
          class="ls-sidecart-product__remove"
          type="secondary"
          size="small"
          :activated="removing"
          :disabled="loading"
          @click.prevent="removeFromCart(index)"
        >
          {{ $content.global.remove }}
        </base-button>
        <div class="ls-sidecart-product__prices">
          <span
            v-if="product.struckPrice"
            class="ls-sidecart-product__struck-price"
          >
            <display-price :amount="product.struckPrice" />
          </span>
          <span
            v-if="selectedPrice"
            class="ls-sidecart-product__amount"
          >
            <display-price :amount="selectedPrice" />
          </span>
        </div>
      </span>
    </span>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import {
  BaseButton,
} from '@loophq/design-system';
import { getSizedImageUrl } from './filters';
import { trackEvent } from '@/js/helpers/trackEvent';
import DisplayPrice from '@/components/globals/DisplayPrice.vue';

const props = defineProps({
  products: {
    type: Array,
    required: false,
  },
  theme: {
    type: Object,
    required: true,
  },
  product: {
    type: Object,
    required: true,
  },
  variant: {
    type: Object,
    required: false,
  },
  index: {
    type: Number,
    required: true,
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['update']);

const removing = ref(false);

const image = computed(() => props.product.image ? getSizedImageUrl(props.product.image, '176x176') : null);
const selectedPrice = computed(() => props.product.price);
const variants = computed(() => props.product.variant_title === 'Default Title' ? '' : props.product.variant_title);

const removeFromCart = (removeIndex) => {
  removing.value = true;
  trackEvent('remove from cart');
  emit('update', props.products.filter((_, index) => index !== removeIndex));
};
</script>

<style lang="scss" scoped>
.ls-sidecart-product {
  width: 100%;
  background: $base-background;
  color: var(--theme-body-color);
  display: flex;
  padding: 1.25rem;
  border-bottom: 1px solid rgba(60, 64, 67, 8%);

  &__image-badge {
    position: absolute;
    width: fit-content;
    background-color: var(--grey-900);
    border-radius: var(--spacing-900);
    border: 2px solid white;
    padding: var(--spacing-100) calc(var(--spacing-200) + 2px);
    color: white;
    text-align: center;
    font-size: 0.75rem;
    bottom: -5%;
    right: -10%;
  }

  &__image {
    flex-shrink: 0;
    width: 88px;
    height: 88px;
    background-color: var(--product-background-color);
    position: relative;
    border-radius: v-bind('theme.cornerRadius');

    img {
      width: 100%;
      height: 100%;
      object-fit: var(--product-image-fit, cover);
      object-position: var(--product-image-position);
    }
  }

  &__info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
  }

  &__title {
    font-family: $font-stack;
    font-size: 0.9375rem;
    font-weight: 500;
    color: var(--theme-heading-color);
    margin-bottom: 0.25rem;
  }

  &__variants {
    font-family: v-bind('theme.copyFont') $font-stack;
    font-size: 0.9375rem;
    color: var(--theme-body-color);
  }

  &__footer {
    margin-top: auto;
    padding-top: 0.5rem;
    display: flex;
    align-items: center;
  }

  &__remove {
    margin-right: 0.5rem;
    padding: 0.375rem 0.5rem;
    font-size: 0.75rem;
  }

  &__prices {
    margin-left: auto;
    display: flex;
    flex-direction: column;
  }

  &__struck-price {
    text-decoration: line-through;
    color: var(--grey-900);
  }

  &__amount {
    font-family: v-bind('theme.copyFont') $font-stack;
    font-size: 0.9375rem;
    color: var(--theme-body-color);
  }
}
</style>
